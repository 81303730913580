import api from "..";

import hubSpotService from "../prequal/hubSpotService";

const booleanLeadFields = {
  owns_property: true,
  current_residence: true,
  needs_financing: true,
  needs_contractor: true,
  needs_architect: true,
}

// Get Leads
const getLeads = async () => {
  // Make Request, Include Response in Variable
  const response = await api.get("/leads");

  return response.data;
};

const createLead = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.post("/leads", request);

  return response.data;
};

const updateLead = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.put("/leads", request);

  let dataFromAirtable = response.data[0].fields;

  // Airtable does not return boolean fields that are set to false,
  // so after we get our data back from Airtable, be sure to explicity
  // copy the boolean fields back into state so they're available if the 
  // user navigates back to this page
  for (const key of Object.keys(request)) {
    if ( booleanLeadFields[key] ) {
      dataFromAirtable[key] = request[key];
    }
  }

  if ( request.email !== undefined ) {  
    hubSpotService.upsertHubSpot({formData: {
      ...request,
      Created: new Date().toISOString()
    }});
  }

  return dataFromAirtable;
};

const deleteLead = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.delete("/leads", request);

  return response.data;
};

const leadService = {
  getLeads,
  createLead,
  updateLead,
  deleteLead,
};

export default leadService;
