
  import React from "react";

  const ResultsPage = ({ children }) => {
    return (
      <>
        <div className="overflow-hidden bg-transparent py-8 sm:py-12">
          <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 md:grid-cols-2 lg:items-start">
              {/* Left Side */}
              <div className="hidden md:flex sm:px-6 lg:px-0">
                <div className="relative isolate overflow-hidden bg-red-300 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-8 sm:pl-8 sm:pr-0 lg:mx-0 lg:max-w-m">
                  <div
                    className="absolute -inset-y-px -left-4 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-[#FDF0E6] opacity-20 ring-1 ring-inset ring-white"
                    aria-hidden="true"
                  />
                  <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                    <img
                      src="https://cdn.prod.website-files.com/63b71a6727a161f30cbd6e3e/671ac3e080975a44f58be559_reseda-exterior-vertical-crop.jpg"
                      alt="Product screenshot"
                      width={618}
                      height={768}
                      className="rounded-tl-xl"
                    />
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 sm:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>

              {/* Right Side */}
              { children }
            </div>
          </div>
        </div>
      </>
    );
  };

  export default ResultsPage;