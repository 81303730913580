import React, { useState } from "react";
import Helmet from "react-helmet";

import {
  ContainerLayout,
  Header,
  ProgressBar,
  Footer,
} from "../components";

import { PriceYourADUStep1 } from "../components/organisms/PriceYourADUStep1";
import { PriceYourADUStep2 } from "../components/organisms/PriceYourADUStep2";
import { PriceYourADUStep3 } from "../components/organisms/PriceYourADUStep3";
import { PriceYourADUStep4 } from "../components/organisms/PriceYourADUStep4";
import { PriceYourADUStep5 } from "../components/organisms/PriceYourADUStep5";
import { PriceYourADUCompleted } from "../components/organisms/PriceYourADUCompleted";
import PageTracker from "../components/templates/PageTracker.js";

const Intake = () => {
  const [page, setPage] = useState(0);

  const formSteps = [
    <PriceYourADUStep1 page={page} setPage={setPage} />,
    <PriceYourADUStep2 page={page} setPage={setPage} />,
    <PriceYourADUStep3 page={page} setPage={setPage} />,
    <PriceYourADUStep4 page={page} setPage={setPage} />,
    <PriceYourADUStep5 page={page} setPage={setPage} />,
    <PriceYourADUCompleted page={page} setPage={setPage} />,
  ];
  
  return (
    <>
      <Helmet>
        <title>Price Your ADU</title>
      </Helmet>
      <PageTracker page={page}>
        <Header numSteps={formSteps.length} currentStep={page + 1} />
        <ProgressBar numSteps={formSteps.length} currentStep={page} />
        <ContainerLayout>{formSteps[page]}</ContainerLayout>
        <Footer page={page} setPage={setPage} />
      </PageTracker>
    </>
  );
};

export default Intake;
