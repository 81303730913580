import api from "..";

// Get ADU Pricing
const getADUPricing = async (params) => {
  // Make Request, Include Response in Variable
  const querystring = new URLSearchParams(params).toString();
  const response = await api.get("/adupricing?" + querystring);

  if ( response.status !== 200 ) {
    throw Error(`Unexpected response status: ${response.status}, expected 200`)
  }

  return response.data;
};

export default getADUPricing;
