const prod = {
  url: {
    API_URL: `/functions`,
  },
};
const dev = {
  url: {
    API_URL: "http://localhost:3001/functions",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
