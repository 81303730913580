import React, { useEffect } from 'react';
import { trackPriceMyADUInteraction} from '../../features/track/track.js';

const PageTracker = ({ children, page }) => {
  useEffect(() => {
    trackPriceMyADUInteraction();
  }, [page]);

  return <>{children}</>;
};

export default PageTracker;