import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

const pixelIds = {
  PriceMyADU: '1066931508471628',
  Lead: '579268767458134',
};

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};

ReactPixel.init(pixelIds.PriceMyADU, {}, options);
ReactPixel.init(pixelIds.Lead, {}, options);
ReactGA.initialize('G-7443514S7S');

export const trackPriceMyADUInteraction = () => {
  ReactPixel.trackSingleCustom(pixelIds.PriceMyADU,'PriceMyADU');

  ReactGA.send({
    hitType: 'pageview',
    page: {
      url: '/price-your-adu',
      title: 'Price Your ADU',
    }
  });
};

export const trackLead = () => {
  // Facebook Pixel
  ReactPixel.trackSingleCustom(pixelIds.Lead,'Lead');

  // Google Ads Conversion Tracking
  // Track with gtag
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-10860885916/YGBfCIOx8OIZEJzv77oo',
      'value': 1.0,
      'currency': 'USD'
    });
  }
};