import React from "react";
import { Outlet } from "react-router-dom";

export const RootLayout = () => {
  return (
    <>
      <div className="min-h-screen justify-center items-center bg-[#FDF0E6] mx-auto">
        <Outlet />
      </div>
    </>
  );
};
