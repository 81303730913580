import React, { useEffect, useState, useRef } from "react";
import { FormLayout, Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePriceYourADU } from "../../features/priceyouradu/priceYourADUSlice";
import getADUPricing from "../../features/adupricing/aduPricingService";
import { trackLead } from "../../features/track/track";
import { Turnstile } from "@marsidev/react-turnstile";

export const PriceYourADUStep5 = ({ page, setPage }) => {
  const dispatch = useDispatch();
  const [turnstileStatus, setTurnstileStatus] = useState();
  const [turnstileToken, setTurnstileToken] = useState();
  const [turnstileMessage, setTurnstileMessage] = useState('');
  const turnstileRef = useRef();

  const { recordId, formData } = useSelector((state) => state.priceyouradu);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { isValid },
  } = useForm({});

  const onSubmit = async (data) => {
    var turnstileResult;

    if ( turnstileStatus === undefined ) {
      setTurnstileMessage("Please complete verification below")
      return;
    } else if ( turnstileStatus === 'error' ) {
      turnstileResult = "failed";
    } else if ( turnstileStatus === 'success' ) {
      // Validate Turnstile token
      const res = await fetch('/functions/validate-turnstile-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ turnstileToken }),
      });

      const turnstileValidation = await res.json();

      if ( ! turnstileValidation.success ) {
        turnstileResult = "token validation failed";
        setTurnstileStatus('token validation failed');
      }

      turnstileResult = "success";
    } else {
      turnstileResult = "unsure";
    }

    // If we have a turnstile failure, we go ahead and allow the pricing requsest
    // to be made, so we can store the turnstile result. We do not advance to the next page.
    // The Hubspot contact will not be created because it is only created if turnstile_result
    // is sent as "success"
    const request = {
      ...formData,
      id: recordId,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: data.phone_number,
      sms_opt_in: data.sms_opt_in,
      phone_opt_in: data.phone_opt_in,
      email_opt_in: data.email_opt_in,
      turnstile_result: turnstileResult,
    };

    getADUPricing({
      adu_size: formData.adu_size,
      adu_type: formData.adu_type === "Not Sure" ? "New Construction" : formData.adu_type,
      adu_location: formData.adu_location,
    }).then(aduPricing => {
      console.log("ADU Pricing", aduPricing)
      return dispatch(updatePriceYourADU({
        ...request,
        low_estimate: aduPricing.low_estimate,
        medium_estimate: aduPricing.medium_estimate,
        high_estimate: aduPricing.high_estimate,
        low_value_increase_percent: aduPricing.low_value_increase_percent,
        medium_value_increase_percent: aduPricing.medium_value_increase_percent,
        high_value_increase_percent: aduPricing.high_value_increase_percent,
      }));
    }).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        // If we don't have a turnstile validation, do not advance to the next page
        if ( turnstileResult !== "success" ) {
          return 
        }

        // Otherwise we have a successful turnstile validation, let the user continue
        trackLead();
        setPage(page + 1);
      }
    }).catch(e => {
      console.error(e)
    });

  };

  useEffect(() => {
    if (formData) {
      setValue("first_name", formData.first_name);
      setValue("last_name", formData.last_name);
      setValue("email", formData.email);
      setValue("phone_number", formData.phone_number);
    }
  }, [formData, setValue]);

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>{/* <HeaderText text={"Contact Information"} /> */}</>
        }
        mainComponent={
          <div className="px-6 md:px-0 lg:pt-4 lg:pl-4 my-6">
            <div className="mb-8">
              <h1 className="text-4xl sm:text-5xl text-center font-bold tracking-wide leading-tight text-neutral-900">
                Almost there!
              </h1>
            </div>
            <div className="grid mx-auto max-w-2xl grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    autoFocus="autofocus"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    {...register("first_name")}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    {...register("last_name")}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    {...register("email", { required: true })}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone Number
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country"
                      className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm"
                    >
                      <option>US</option>
                      <option>CA</option>
                      <option>EU</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    placeholder="+1 (555) 987-6543"
                    {...register("phone_number", { required: true })}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <div className="mb-2">
                  How would you like us to contact you?
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="sms_opt_in"
                    id="sms_opt_in"
                    className="mr-2"
                    {...register("sms_opt_in")}
                  />
                  <label htmlFor="sms_opt_in" className="mr-2">
                  Text Message
                  </label>
                </div>
                <div>
                <input
                  type="checkbox"
                  name="phone_opt_in"
                  id="phone_opt_in"
                  className="mr-2"
                  {...register("phone_opt_in")}
                />
                <label htmlFor="phone_opt_in" className="mr-2">
                Phone Call
                </label>
                </div>
                <div>
                <input
                  type="checkbox"
                  name="email_opt_in"
                  id="email_opt_in"
                  className="mr-2"
                  {...register("email_opt_in")}
                />
                <label htmlFor="email_opt_in" className="mr-2">
                Email
                </label>
                </div>
              </div>
            </div>
            <div className="text-red-500 flex flex-row justify-center items-center">
              {turnstileMessage}
            </div>
            <Turnstile
              ref={turnstileRef}
              siteKey="0x4AAAAAAAy67B2oY1tvrp3B"
              className="flex flex-row justify-center items-center"
              options={{
                theme: 'light',
              }}
              onError={() => setTurnstileStatus('error')}
              onSuccess={(token) => {
                setTurnstileStatus('success');
                setTurnstileToken(token);
              }}
            />
            <div className="mt-9 flex justify-center sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                Get My ADU Price
              </Button>
            </div>
          </div>
        }
        footerComponent={<></>}
      />
    </>
  );
};
