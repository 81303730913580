// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import priceYourADUService from "./priceYourADUService";

// Create Initial State for PreQual form
const initialState = {
  recordId: "",
  formData: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const createPriceYourADU = createAsyncThunk(
  "priceyouradu/create",
  async (request, thunkAPI) => {
    try {
      return await priceYourADUService.create(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePriceYourADU = createAsyncThunk(
  "priceyouradu/update",
  async (request, thunkAPI) => {
    try {
      return await priceYourADUService.update(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const priceYourADUSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      // Create PriceYourADU
      .addCase(createPriceYourADU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPriceYourADU.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.recordId = action.payload.id;
        state.formData = action.payload;
      })
      .addCase(createPriceYourADU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Update PriceYourADU
      .addCase(updatePriceYourADU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePriceYourADU.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formData = action.payload;
      })
      .addCase(updatePriceYourADU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = priceYourADUSlice.actions;
export default priceYourADUSlice.reducer;
