import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import { NumericFormat } from "react-number-format";
import ResultsPage from "../../components/templates/ResultsPage";

export const PriceYourADUCompleted = ({ page, setPage }) => {
  const { formData } = useSelector((state) => state.priceyouradu);
  const adu_size_display = {
    "Studio": "studio",
    "1 Bedroom": "1 bedroom",
    "2 Bedrooms": "2 bedroom",
    "3+ Bedrooms": "3+ bedroom"
  };

  const adu_type_display = {
    "Garage Conversion": " garage conversion",
    "New Construction": " new construction",
    "Not Sure": ""
  };

  const calendly_querystring = new URLSearchParams({
    name: formData.first_name + " " + formData.last_name,
    email: formData.email,
    a1: formData.phone_number.startsWith("1") ? formData.phone_number : "1" + formData.phone_number,
  }).toString();

  return (
    <ResultsPage>
      {/* Right Side */}
      <div className="px-6 md:px-0 lg:pt-4 lg:pl-4 my-6">
        <div className="mb-8">
          <h1 className="text-center text-4xl font-bold tracking-wide leading-tight text-neutral-900">
          Your Revival Homes ADU Estimate
          </h1>
        </div>

        <div className="mb-8">
          <div className="mt-1 text-sm leading-6 text-gray-600 mb-4 ml-1">
            <p className="italic">For a {adu_size_display[formData.adu_size]}, {formData.adu_location.toLowerCase()}{adu_type_display[formData.adu_type]}:</p>

            <p className="mt-2">We estimate a construction cost between <NumericFormat value={formData.low_estimate} displayType="text" thousandSeparator="," prefix="$" /> 
            <span> and </span><NumericFormat value={formData.high_estimate} displayType="text" thousandSeparator="," prefix="$" />.</p>

            <p className="mt-2">We forecast that the ADU will increase your home's value by {formData.low_value_increase_percent}%
            to {formData.high_value_increase_percent}%.</p>

            <p className="mt-2 italic">Got ADU Questions? We've got answers.</p>
          </div>
            <Button
              type={"submit"}
              variant={"primary"}
              size={"large"}
              onClick={() => window.location.href = "https://calendly.com/d/364-y4b-zrz/free-phone-consultation-with-revival-homes-adu-experts?" + calendly_querystring} 
            >
            Book your Free consultation with our ADU experts
            </Button>
        </div>
      </div>
    </ResultsPage>
  );
};
