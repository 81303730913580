import api from "..";

import hubSpotService from "./hubSpotService";

// Get PreQual
const getPreQual = async () => {
  // Make Request, Include Response in Variable
  const response = await api.get("/prequal");

  return response.data;
};

const createPreQual = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.post("/prequal", request);

  if ( request.email !== undefined ) {  
    hubSpotService.upsertHubSpot({formData: {
      ...request,
      Created: new Date().toISOString()
    }}).then((response) => {
      if ( response.statusCode === 200 ) {
        var hsq = window.hsq = window.hsq || [];
        hsq.identify({email: request.email});
      }
    });
  }

  return response.data;
};

const updatePreQual = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.put("/prequal", request);

  if ( request.email !== undefined ) {  
    hubSpotService.upsertHubSpot({formData: {
      ...request,
      Created: new Date().toISOString()
    }}).then((response) => {
      if ( response.status === 200 ) {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['identify',{email: request.email}]);
        _hsq.push(['trackPageView']);
      }
    });
  }

  return response.data;
};

const deletePreQual = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.delete("/prequal", request);

  return response.data;
};

const preQualService = {
  getPreQual,
  createPreQual,
  updatePreQual,
  deletePreQual,
};

export default preQualService;
