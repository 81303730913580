import React from "react";

import logo from "../../assets/rh-logo-dark.png";

export const userNavigation = [
  {
    name: "Legal",
    href: "#",
    onClick: () => {
    },
  },
  {
    name: "Contact",
    href: "#",
    onClick: () => {
    },
  },
  {
    name: "FAQ",
    href: "#",
    onClick: () => {
    },
  },
  {
    name: "Log Out",
    href: "#",
    onClick: () => {
    },
  },
];

export const Header = ({ numSteps, currentStep }) => {
  return (
    <>
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-8xl items-center justify-between p-4 lg:px-12"
          aria-label="Global"
        >
          <a href="https://revivaladu.com" className="-m-1.5 p-1.5">
            <span className="sr-only">Revival ADU</span>
            <img className="h-12 w-auto" src={logo} alt="bundle" />
          </a>
          { currentStep <= numSteps - 1 && 
          <div className="ml-4 mt-2 flex-shrink-0">
            <span className="text-base font-medium tracking-wide leading-tight text-neutral-900">
              Step {currentStep ? currentStep : ""}
            </span>
            <span className="text-base font-bold tracking-wide leading-tight text-neutral-500">
              {" "}
              / { numSteps - 1}
            </span>
          </div>  
          }
        </nav>
      </header>
    </>
  );
};
