import React, { useState, useEffect, useRef } from "react";
import hubSpotService from "../features/prequal/hubSpotService";
import { useDispatch } from "react-redux";
import { createLead } from "../features/lead/leadSlice";

import {
  ContainerLayout,
  Header,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  // I consolidated Step9 and Step10 into Step8
  Step11,
  Step12,
  Step13,
  Completed,
  ProgressBar,
  Footer,
} from "../components";

const Intake = () => {
  const searchParamsRef = useRef(new URLSearchParams(window.location.search));

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const formSteps = [
    <Step1 page={page} setPage={setPage} />, // Address
    <Step7 page={page} setPage={setPage} />, // Project Stage (exploring, design, etc)
    <Step2 page={page} setPage={setPage} />, // Own Property
    <Step3 page={page} setPage={setPage} />, // Live at Property
    <Step4 page={page} setPage={setPage} />, // ADU Size (bedrooms)
    <Step5 page={page} setPage={setPage} />, // ADU Type (conversion, new construction)
    <Step6 page={page} setPage={setPage} />, // Project Reason (family, rental, etc)
    <Step8 page={page} setPage={setPage} />, // Project Needs (architect, contractor)
    <Step9 page={page} setPage={setPage} />, // Plan To Pay
    <Step11 page={page} setPage={setPage} />, // Budget
    <Step12 page={page} setPage={setPage} />, // Additional Information
    <Step13 page={page} setPage={setPage} />, // Contact Information
    <Completed page={page} setPage={setPage} />,
  ];
  
  const [inUseFormSteps, setInUseFormSteps] = useState([ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const email = searchParamsRef.current.get("invitee_email");

    const fetchHubSpotData = async () => {
      let contactInfo = await hubSpotService.lookupHubSpot(email);

      if ( contactInfo.data.has_address ) {
        // If we got an address, we can skip Step 1 (index 0, address) and 13th (index 11, budget) steps
        // This is extra confusing because the array is 0-indexed, but the steps are 1-indexed
        // and further I have eliminated the fromer step 10, so the index is off by 1 at the top of the
        // array
        var inUseFormSteps = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
        
        // If we have ADU size, we can skip step 5
        if ( contactInfo.data.has_adu_size ) {
          inUseFormSteps = inUseFormSteps.filter( step => step !== 4 )
        }

        // If we have ADU type, we can skip step 6
        if ( contactInfo.data.has_adu_size ) {
          inUseFormSteps = inUseFormSteps.filter( step => step !== 5 )
        }

        setInUseFormSteps(inUseFormSteps);
        dispatch(createLead({ email: email})).then(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }

    if ( email === null ) {
      setLoading(false);
    } else {
      fetchHubSpotData();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header numSteps={inUseFormSteps.length} currentStep={page + 1} />
      <ProgressBar numSteps={inUseFormSteps.length} currentStep={page} />
      <ContainerLayout>{formSteps[inUseFormSteps[page]]}</ContainerLayout>
      <Footer page={page} setPage={setPage} />
    </>
  );
};

export default Intake;
