import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import ResultsPage from "../../components/templates/ResultsPage";

export const Completed = ({ page, setPage }) => {
  const { formData } = useSelector((state) => state.lead);
  const navigate = useNavigate();

  var continueToPreQual = false;

  if ( formData.plan_to_pay !== undefined ) {
    if ( formData.plan_to_pay.includes("I need Revival Homes' help to get financing")
         || formData.plan_to_pay.includes("I don't know") )
    {
      continueToPreQual = true;
    }
  }

  return (
    <ResultsPage>
      {/* Right Side */}
      <div className="px-6 md:px-0 lg:pt-4 lg:pl-4 my-6">
        <div className="mb-8">
          <h1 className="text-left text-5xl font-bold tracking-wide leading-tight text-neutral-900">
            { continueToPreQual ? "Thank you for your responses!" : "Talk to you soon!" }
          </h1>
        </div>

        <div className="mb-8">
            {continueToPreQual ? (
              <>
              <p className="mt-1 text-sm leading-6 text-gray-600 mb-4 ml-1">
              You mentioned that you’re looking for some help financing your ADU. If you answer a few short questions, our ADU experts can determine what loan products may be the best fit for your project.
              </p>
                <Button
                  type={"submit"}
                  variant={"primary"}
                  size={"large"}
                  onClick={() => navigate("/pq")}
                >
                  Next
                </Button>
              </>
            ) : (
              <p className="mt-1 text-sm leading-6 text-gray-600">
              Thank you for your submission. One of our ADU experts will be in touch shortly.
              </p>
            )}
        </div>
      </div>
    </ResultsPage>
  );
};
