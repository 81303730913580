import React, { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { FormLayout, Button, HeaderText } from "../../components";
import {
  CheckCircleIcon,
  HomeIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateLead } from "../../features/lead/leadSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const options = [
  {
    id: 1,
    title: "I'm exploring whether to build an ADU",
    description: "I'm exploring whether to build an ADU",
    icon: (
      <CheckCircleIcon
        className={classNames("h-9 w-9 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "I'm exploring whether to build an ADU",
  },
  {
    id: 2,
    title: "I'm ready to start my ADU design and permitting",
    description: "I'm ready to start my ADU design and permitting",
    icon: (
      <HomeIcon
        className={classNames("h-9 w-9 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "I'm ready to start my ADU design and permitting",
  },
  {
    id: 3,
    title: "I've started my ADU design and permitting, and I need help",
    description: "I've started my ADU design and permitting, and I need help",
    icon: (
      <HomeIcon
        className={classNames("h-9 w-9 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "I've started my ADU design and permitting, and I need help",
  },
  {
    id: 4,
    title: "My ADU permit is approved, and I'm looking for a contractor",
    description: "My ADU permit is approved, and I'm looking for a contractor",
    icon: (
      <HomeIcon
        className={classNames("h-9 w-9 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "My ADU permit is approved, and I'm looking for a contractor",
  },
  {
    id: 5,
    title: "My ADU is under construction, and I need a new contractor",
    description: "My ADU is under construction, and I need a new contractor",
    icon: (
      <HomeIcon
        className={classNames("h-9 w-9 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "My ADU is under construction, and I need a new contractor",
  },
  {
    id: 6,
    title: "I want to legalize an existing unpermitted ADU on my property",
    description: "I want to legalize an existing unpermitted ADU on my property",
    icon: (
      <WrenchScrewdriverIcon
        className={classNames("h-9 w-9 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "I want to legalize an existing unpermitted ADU on my property",
  },
];

export const Step7 = ({ page, setPage }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const { recordId, formData } = useSelector((state) => state.lead);

  const {
    handleSubmit,
  } = useForm({});

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: formData.email,
      project_stage: selected.value,
    };

    dispatch(updateLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  useEffect(() => {
    if (formData.project_stage !== undefined) {
      const option = options.find(
        (option) => option.value === formData.project_stage
      );
      setSelected(option);
    }
  }, [ formData.project_stage ]);

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        preHeaderMessage={formData.email !== undefined ? "Thank you for scheduling your consultation. Please answer a few questions to help us better understand your project." : undefined}
        headerComponent={
          <>
            <HeaderText text={"What stage is your project at?"} />
          </>
        }
        mainComponent={
          <>
            <div className="flex py-4 max-w-2xl justify-center mx-auto">
              <div className="mt-4 w-full">
                <RadioGroup value={selected} onChange={setSelected}>
                  <RadioGroup.Label className="sr-only">
                    {" "}
                    Server size{" "}
                  </RadioGroup.Label>
                  <div className="space-y-3">
                    {options.map((option) => (
                      <RadioGroup.Option
                        key={option.id}
                        value={option}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? "border-transparent" : "border-gray-300",
                            active ? "border-red-400 ring-2 ring-red-400" : "",
                            "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm text-base focus:outline-none sm:flex sm:justify-between"
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span className="flex items-center">
                              <span className="flex flex-col text-base">
                                <RadioGroup.Label
                                  as="span"
                                  className="font-medium text-gray-900"
                                >
                                  {option.title}
                                </RadioGroup.Label>
                                {/* <RadioGroup.Description
                              as="span"
                              className="text-gray-500"
                            >
                              <span className="block sm:inline">
                                {option.description}
                              </span>
                            </RadioGroup.Description> */}
                              </span>
                            </span>
                            {/* <RadioGroup.Description
                              as="span"
                              className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                            >
                              <span className="font-medium text-gray-900">
                                {option.icon}
                              </span>
                            </RadioGroup.Description> */}
                            <span
                              className={classNames(
                                active ? "border" : "border-2",
                                checked
                                  ? "border-red-400"
                                  : "border-transparent",
                                "pointer-events-none absolute -inset-px rounded-lg"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
