import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { FormLayout, Button, HeaderText, RadioCard } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePriceYourADU } from "../../features/priceyouradu/priceYourADUSlice";
import { ReactComponent as DetachedIcon } from "../../assets/detached.svg"
import { ReactComponent as AttachedIcon } from "../../assets/attached.svg"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const options = [
  {
    id: 1,
    title: "Attached",
    description: "Attached",
    icon: (
      <AttachedIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "Attached",
  },
  {
    id: 2,
    title: "Detached",
    description: "Detached",
    icon: (
      <DetachedIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "Detached",
  },
];

export const PriceYourADUStep3 = ({ page, setPage }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const { recordId, formData } = useSelector((state) => state.priceyouradu);

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected(option);
    // setPage(page + 1);
  };

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      adu_location: selected.value,
    };

    dispatch(updatePriceYourADU(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  useEffect(() => {
    if (formData.adu_location !== undefined) {
      const option = options.find(
        (option) => option.value === formData.adu_location
      );
      setSelected(option);
    }
  }, [ formData.adu_location ]);

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"What type of ADU do you want?"} />
          </>
        }
        mainComponent={
          <>
            <div>
              <RadioGroup value={selected} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                  Select Option
                </RadioGroup.Label>
                <div className="flex justify-center p-4">
                  <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
                    {options.map((option) => (
                      <RadioCard key={option.id} item={option} />
                    ))}
                  </div>
                </div>
              </RadioGroup>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"button"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
