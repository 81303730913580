import { useEffect, useState } from 'react';

const useLoadScript = (url, id) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (document.getElementById(id)) {
      setLoaded(true);
      return
    }

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, id]);

  return loaded;
};

export default useLoadScript;