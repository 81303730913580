import axios from "axios";
import { config } from "../config/constants";

// create an axios instance
const api = axios.create({
  // baseURL: "http://localhost:3000/v1",
  baseURL: config.url.API_URL,
});

// add a request interceptor
api.interceptors.request.use((config) => {
  return config;
});

// add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // if the request returns a 401 status code, try to refresh the access token
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accessTokenExpires");
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        // send a request to the backend API to refresh the access token
        try {
          const response = await api.post("/auth/refresh-tokens", {
            refreshToken,
          });
          // if the refresh request is successful, store the new access token in the local storage
          // and retry the original request
          localStorage.setItem(
            "accessToken",
            response.data.tokens.access.token
          );
          localStorage.setItem(
            "accessTokenExpires",
            response.data.tokens.access.expires
          );
          return await api.request(error.config);
        } catch (err) {
          // if the refresh request fails, remove the refresh token from the local storage
          // and redirect the user to the login page
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("refreshTokenExpires");
          localStorage.removeItem("user");
          // window.location.href = "/login";
          return await Promise.reject(err);
        }
      } else {
        // if there is no refresh token, redirect the user to the login page
        // window.location.href = "/login";
        return Promise.reject(error);
      }
    } else {
      // if the error is not a 401 response, return the error as-is
      return Promise.reject(error);
    }
  }
);

export default api;
