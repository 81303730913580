import React, { useEffect } from 'react';
import { HeaderText } from "../atoms/HeaderText";
import { Button, FormLayout } from "../../components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePriceYourADU } from "../../features/priceyouradu/priceYourADUSlice";
import useLoadScript from "../../hooks/useLoadScript";

export function PriceYourADUStep4({ page, setPage }) {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.priceyouradu);

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm({});

  window.initAutocomplete = () => {
      const input = document.getElementById('street_address');
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;

        const getAddressComponent = (type) => {
          return addressComponents.find(component => component.types.includes(type))?.short_name || '';
        };

        setValue('street_address',
          getAddressComponent('street_number') + ' ' + getAddressComponent('route')
          + ', ' + getAddressComponent('locality') + ', ' + getAddressComponent('administrative_area_level_1') + ' ' + getAddressComponent('postal_code')
        );
        setValue('address_line1', getAddressComponent('street_number') + ' ' + getAddressComponent('route'));
        setValue('city', getAddressComponent('locality'));
        setValue('state', getAddressComponent('administrative_area_level_1'));
        setValue('postal_code', getAddressComponent('postal_code'));
        trigger(); // Validate the form to update the form state
      });
  }

  useLoadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyALlL-KEK1J7MIxY3xhATz-U-RwPqaWkcs&libraries=places&callback=initAutocomplete', 'google-maps-script');

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      street_address: data.street_address,
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      city: data.city,
      state: data.state,
      zip_code: data.postal_code,
    };

    dispatch(updatePriceYourADU(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  useEffect(() => {
    if ( formData ) {
      setValue("street_address", formData.street_address);
      setValue("address_line1", formData.address_line1);
      setValue("address_line2", formData.address_line2);
      setValue("city", formData.city);
      setValue("state", formData.state);
      setValue("postal_code", formData.zip_code);
      trigger(); // Validate the form to update the form state
    }
  }, [formData, setValue, trigger]);

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"Where do you want to build your ADU?"} />
          </>
        }
        mainComponent={
          <>
            <input
              type="hidden"
              name="address_line1"
              id="address-line1"
              {...register("address_line1", { required: false })}
            />
            <input
              type="hidden"
              name="address_line2"
              id="address-line2"
              {...register("address_line2", { required: false })}
            />
            <input
              type="hidden"
              name="city"
              id="city"
              {...register("city", { required: false })}
            />
            <input
              type="hidden"
              name="state"
              id="state"
              {...register("state", { required: false })}
            />
            <input
              type="hidden"
              name="postal_code"
              id="postal_code"
              {...register("postal_code", { required: false })}
            />
            <div className="py-4">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:max-w-3xl mx-auto">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="address_line1"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Street address
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="street_address"
                      id="street_address"
                      autoComplete="street_address"
                      autoFocus="autofocus"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                      {...register("street_address", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                Continue
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}
